import styled from "styled-components"

const TeamSectionWrapper = styled.section`
  overflow: hidden;
  .react-reveal {
    display: flex;
    justify-content: center;
  }
  .text-flex {
    width: calc(45% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
      justify-content: center;
      width: 35%;
    }
    @media (max-width: 425px) {
      justify-content: center;
      width: 70%;
    }
  }
  .boxSizees {
    height: 385px;

    @media (max-width: 1440px) {
      height: 460px;
    }
    @media (max-width: 1024px) {
      height: 445px;
    }
    @media (max-width: 768px) {
      height: 300px;
    }
    @media (max-width: 425px) {
      height: 320px;
    }
    @media (max-width: 375px) {
      height: 350px;
    }
  }
  .ceo {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
      width: 78%;
    }
  }
  .bhqpXM {
    @media (max-width: 768px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const SocialLinks = styled.div`
  margin-top: -25%;
  @media (max-width: 1440px) {
    margin-top: -35%;
  }
  @media (max-width: 1219px) {
    margin-top: -55%;
  }
  @media (max-width: 990px) {
    margin-top: -75%;
  }
  @media (max-width: 768px) {
    margin-top: 0%;
    margin-bottom: -20%;
  }
  @media (max-width: 675px) {
    margin-top: 6%;
    margin-bottom: -25%;
  }
  @media (max-width: 575px) {
    margin-top: 0%;
    margin-bottom: -12%;
  }
  @media (max-width: 425px) {
    margin-top: 3% !important;
    margin-bottom: 0 !important;
  }
  @media (max-width: 375px) {
    margin-top: 2%;
    margin-bottom: -16%;
  }
  @media (max-width: 340px) {
    margin-top: 18%;
    margin-bottom: -30%;
  }
  @media (max-width: 320px) {
    margin-top: 33%;
    margin-bottom: -51%;
  }

  > button,
  a {
    margin: 3px;
    cursor: pointer;
    display: inline-block;
    i {
      display: block;
      width: 28px;
      height: 23px;
      line-height: 20px;
      background-color: transparent;
      color: #dddddd;
      padding: 1px 6px;
      font-size: 18px;
      transition: all 0.2s ease;
    }
    &:hover {
      img {
        filter: grayscale(0);
      }
    }

    img {
      filter: grayscale(1);
      transition: 0.3s ease-in-out;
      padding: 25px;
    }
  }
`

const ContainerA = styled.section`
  .row-col-flex {
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
  .iJXKs {
    padding-left: 3%;
    padding-right: 3%;
    @media screen and (max-width: 768px) {
      width: 65%;
      margin-bottom: 14%;
    }
    @media screen and (max-width: 575px) {
      width: 90%;
      margin-bottom: 14%;
    }
    @media (max-width: 425px) {
      width: 94%;
      margin-bottom: 32%;
    }
    @media (max-width: 375px) {
      width: 94%;
      margin-bottom: 19%;
    }

    @media (max-width: 320px) {
      width: 92%;
      margin-bottom: 45%;
    }
  }
`
export { SocialLinks, ContainerA }
export default TeamSectionWrapper
