/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../../components/Box';
import Text from '../../../components/Text';
import Heading from '../../../components/Heading';
import Image from '../../../components/Image';
import Input from '../../../components/Input';
import Container from '../../../components/Container';
import Send from '../../../assets/images/about/send.png'
import CheckBox from '../../../components/Checkbox/index';
import { useToggle } from '../../../hooks/index';
import { useStaticQuery, graphql } from 'gatsby';

import NewsletterWrapper, { ContactFormWrapper } from './newsletter.style';

const Newsletter = ({
  sectionWrapper,
  textArea,
  buttonArea,
  buttonStyle,
  title,
  description,
  secdescription,
  tearms,

}) => {
  const [checked, setChecked] = useToggle(checked);
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        NEWS_LETTER {
          title
          description
          secdescription
          tearms
        }
      }
    }
  `);
  return (
    <Box {...sectionWrapper} as="section">
      <NewsletterWrapper>
        <Container>
          <Box {...textArea}>
            <Text
              content={Data.dataJson.NEWS_LETTER.title}
              {...description}
              className='headTitle'
            />
            <Heading content={Data.dataJson.NEWS_LETTER.description} {...title} />

          </Box>
          <Box {...buttonArea}>
            <Box >
              <Text
                content={Data.dataJson.NEWS_LETTER.secdescription}
                {...secdescription}
                className='title'
              />
              <ContactFormWrapper>
                <Input
                  inputType="email"
                  iconposition="right"
                  isMaterial={true}
                  className="email_input"
                  placeholder='hello@39production.com'

                />
                <Image src={Send} alt="" {...buttonStyle} className='reusecore__button' />
              </ContactFormWrapper>

              <CheckBox
                id=""
                htmlFor=""
                checked={checked}
                disabled={false}
                onClick={() => setChecked(checked)}
                labelText={<Text
                content={Data.dataJson.NEWS_LETTER.tearms}
                  {...tearms}
                  className='reusecore__button'
                />}
              />

            </Box>

          </Box>
        </Container>
      </NewsletterWrapper>
    </Box>
  );
};

Newsletter.propTypes = {
  sectionWrapper: PropTypes.object,
  textArea: PropTypes.object,
  buttonArea: PropTypes.object,
  buttonStyle: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  tearms: PropTypes.object,
};

Newsletter.defaultProps = {
  sectionWrapper: {
    mt: '0%',
  },
  textArea: {
    mt: ['40px', '40px', '40px', '-6%', '-8%'],
    pr: ['0', '0', '0', '80px', '40px'],
  },
  title: {
    fontSize: ["28px", "28px", "42px", "38px", "48px", "68px"],
    fontWeight: '700',
    color: '#94d84e',
    lineHeight: '1.24',
    mb: ['14px', '14px', '14px', '14px', '13px'],
    textAlign: ['center', 'center', 'center', 'left', 'left'],
    letterSpacing: '-1.85px',
  },
  description: {
    fontSize: '21.5px',
    maxWidth: ['100%', '400px'],
    color: '#b5b5b6',
    lineHeight: '4.07',
    letterSpacing: '2.15px',
    fontWeight: '700',
    mb: 0,
    textAlign: ['center', 'center', 'right', 'left', 'left'],
  },
  secdescription: {
    fontSize: ["14px", "14px", "20px", "22.5px", "25.5px", "27.5px"],
    width: ['300px','300px','723px','585px','723px','723px'],
    height: '71px',
    mb: '10%',
    fontWeight: '400',
    color: 'rgba(159, 163, 167, 0.9)',
    lineHeight: '1.56',
    letterSpacing: '2.15px',
    opacity: '0.9',
    textAlign: ['center', 'center', 'center', 'left', 'left'],
  },
  buttonArea: {
    zIndex: 1,
    mt: '16%',

  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '700',
    color: '#98e044',
    pl: '30px',
    pr: '30px',
  },
  tearms: {
    fontSize: ["12px", "13px", "15px", "16.5px", "18px", "18px"],
    lineHeight: '1.6',
    textAlign: 'left',
    color: '#8f8f8f',
    mt: ['10%','10%','5%','10%','10%','10%'],
    ml: ['-2%','0'],
    width: ['280px','495px','495px','551px','680px','680px']


  },
};

export default Newsletter;
