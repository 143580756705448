import React from "react"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
import Box from "../../../components/Box"
import Text from "../../../components/Text"
import Heading from "../../../components/Heading"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../components/Image"
import Container from "../../../components/Container"
import GlideCarousel from "../../../components/GlideCarousel"
import GlideSlide from "../../../components/GlideCarousel/glideSlide"
import group from "../../../assets/images/about/Group.png"
import {
  SliderWrapper, VisionWrapper, TestimonialItem,
  TestimonialMeta,
  AuthorInfo,
} from "./style"
const OurVision = ({
  sectionWrapper,
  row,
  col,
  title,
  description,
  subTitle,
  textArea,
  imageArea,
  imageVision,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        VISION_SLIDER {
          designation
          id
          title
          name
        }
      }
    }
  `)

  const carouselOptions = {
    type: "carousel",
    autoplay: 6000,
    perView: 1,
    gap: 30,
    animationDuration: 800,
    breakpoints: {
      990: {
        perView: 1,
      },
    },
  }
  return (
    <VisionWrapper>
      <Box {...sectionWrapper}>
        <Container>
          <Box {...row} width="true">
            <Box {...col} {...textArea}>
              <Fade bottom cascade>
                <SliderWrapper>
                  <GlideCarousel
                    options={carouselOptions}
                    carouselSelector="testimonial__slider"
                    controls={false}
                    bullets={true}
                    numberOfBullets={Data.dataJson.VISION_SLIDER.length}
                  >
                    <>
                      {Data.dataJson.VISION_SLIDER.map((item, index) => (
                        <GlideSlide key={`testimonial-slide-${index}`}>
                          <TestimonialItem>
                            <Text content={item.title}
                             as="h1"
                              {...title} />

                            <TestimonialMeta>
                              <AuthorInfo>
                                <Box>
                                  <Heading
                                   as="h2"
                                    content={item.name}
                                    {...subTitle}
                                  />
                                  <Heading
                                    as="h3"
                                    content={item.designation}
                                    {...description}
                                  />
                                </Box>
                              </AuthorInfo>
                            </TestimonialMeta>
                          </TestimonialItem>
                        </GlideSlide>
                      ))}
                    </>
                  </GlideCarousel>
                </SliderWrapper>
              </Fade>
            </Box>
            <Box {...col} {...imageArea}>
              <Fade right>
                <Image {...imageVision} src={group} alt="Card Image" />
              </Fade>
            </Box>
          </Box>
        </Container>
      </Box>
    </VisionWrapper>
  )
}

OurVision.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  subTitle: PropTypes.object,
  description: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageVision: PropTypes.object,
}

OurVision.defaultProps = {
  sectionWrapper: {
    as: "section",
    pt: ["60px", "80px", "80px", "80px"],
    pb: ["40px", "40px", "40px", "80px"],
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    alignItems: "center",
  },
  imageAreaRow: {
    flexDirection: "row-reverse",
  },
  col: {
    pr: "15px",
    pl: ["0","15px"],
  },
  textArea: {
    width: ["90%", "80%", "55%", "50%", "42%"],
    mb: ["40px", "40px", "0", "0", "0"],
  },
  imageArea: {
    width: ["0%", "0%", "36%", "40%", "50%"],
    mt: "3%",
    ml: "8%",
  },
  title: {
    fontSize: ["17px", "17px", "20.5px", "27.5px", "34px", "35.5px"],
    fontWeight: "700",
    letterSpacing: "-0.025em",
    mb: '20px',
    color: '#ff7e00',
  },
  subTitle: {
    fontSize: ['26px', '40px', '44px', '48px', '52px', '58px'],
    fontWeight: '700',
    color: '#ffffff',
    lineHeight: "1.2",
    letterSpacing: '-1.8px',
  },
  description: {
    fontSize: ["14px", "15.5px", "17.5px", "18.5", "18.5px", "20.5px"],
    fontWeight: ['300','300','300','350','350','350'],
    color: "rgba(255, 255, 255, 0.7)",
    lineHeight: "-0.15px",
    textAlign:'justify'
    
  },

  imageVision: {
    mb: "0px",
    ml: "auto",
    mr: "auto",
  },
}

export default OurVision
