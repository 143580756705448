import styled from "styled-components"
export const SliderWrapper = styled.div`
  margin-top: 15%;
  margin-bottom: 20%;
  margin-left: 20px;
  width: 100%;
  @media (max-width: 1440px) {
    margin-left: 6%;
    margin-top: 21%;
  }
  @media (max-width: 1024px) {
    width: 91%;
  }

  .glide__bullets {
    margin-top: -5%;
    margin-left: 38px;
    text-align: left;
    @media (max-width: 575px) {
      margin-top: 0px;
    }
    .glide__bullet {
      width: 10px;
      height: 10px;
      background: #cbcbcb;
      transition: 0.15s ease-in-out;
      &:hover {
        background: #98e044;
      }
      &.glide__bullet--active {
        background: #98e044;
      }
    }
  }

  @media (max-width: 425px) {
    box-sizing: border-box;
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
    width: 89%;
  }
  @media (max-width: 375px) {
    box-sizing: border-box;
    margin-bottom: 0;
    padding-left: 15px;
    width: 89%;
  }
`

export const SliderContainer = styled.div`
  .flex-img-slider {
    @media (max-width: 1024px) {
      margin-left: 0%;
      margin-top: 0%;
    }
    @media (max-width: 768px) {
      margin-left: 6%;
      margin-top: 0%;
    }
  }
  .flex-box-text {
    width:43%;
    @media (max-width: 1440px) {
      width:40%;
    }
    @media (max-width: 1024px) {
      width:49%;
    }
  }
`
export const TestimonialItem = styled.div`
  position: relative;
  display: block;
  background: transparent;
  font-family: Raleway;
  padding: 47px;
  @media (max-width: 1440px) {
    padding: 19px;
  }
  @media (max-width: 768px) {
    width: 345px;
  }
  @media (max-width: 575px) {
    width: 300px;
  }
  @media (max-width: 425px) {
    width: 300px;
  }
  @media (max-width: 375px) {
    width: 262px;
  }
  @media (max-width: 320px) {
    width: 250px;
  }
  @media (max-width: 575px) {
    padding: 0px;
  }
`
export default SliderWrapper
