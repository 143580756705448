import React from "react"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
import Box from "../../../components/Box"
import Text from "../../../components/Text"
import Heading from "../../../components/Heading"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../components/Image"
import Container from "../../../components/Container"
import GlideSlide from "../../../components/GlideCarousel/glideSlide"
import AboutSlider from "../../../assets/images/about/partners.png"
import SliderWrapper, { TestimonialItem , SliderContainer } from "./style"
import { TestimonialMeta, AuthorInfo } from "../../Landing/Banner/style"
const PaymentSection = ({
  sectionWrapper,
  row,
  col,
  title,
  description,
  subTitle,
  textArea,
  imageArea,
  imageOne,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        SLIDER_ABOUT {
          designation
          id
          name
          title
        }
      }
    }
  `)
  return (
    <SliderContainer>
    <Box {...sectionWrapper}>
      <Container>
        <Box {...row} width="true">
          <Box {...col} {...textArea} className='flex-box-text'>
            <Fade left>
              <SliderWrapper>
                <>
                  {Data.dataJson.SLIDER_ABOUT.map((item, index) => (
                    <GlideSlide key={`testimonial-slide-${index}`}>
                      <TestimonialItem>
                        <Text as="h1" content={item.title} {...title} />

                        <TestimonialMeta>
                          <AuthorInfo>
                            <Box>
                              <Heading
                                as="h2"
                                content={item.name}
                                {...subTitle}
                              />
                              <Heading
                                as="h3"
                                content={item.designation}
                                {...description}
                              />
                            </Box>
                          </AuthorInfo>
                        </TestimonialMeta>
                      </TestimonialItem>
                    </GlideSlide>
                  ))}
                </>
              </SliderWrapper>
            </Fade>
          </Box>
          <Box {...col} {...imageArea} className='flex-img-slider'>
            <Fade right>
              <Image {...imageOne} src={AboutSlider} alt="Card Image" />
            </Fade>
          </Box>
        </Box>
      </Container>
    </Box>
    </SliderContainer>
  )
}

PaymentSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  subTitle: PropTypes.object,
  description: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageOne: PropTypes.object,
  imageTwo: PropTypes.object,
}

PaymentSection.defaultProps = {
  sectionWrapper: {
    as: "section",
    pt: ["30%", "80px", "80px", "80px", "80px"],
    // pb: ["40px", "40px", "40px", "80px"],
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    // ml: ["-40px","-15px"],
    // mr: "-15px",
    alignItems: "center",
  },
  imageAreaRow: {
    flexDirection: "row-reverse",
  },
  col: {
    pr: "15px",
    pl: ["0", "15px"],
  },
  textArea: {
    width: ["90%", "80%", "55%", "50%", "42%"],
    mb: ["0", "0", "0", "0", "0"],
  },
  imageArea: {
    width: ["0%", "0%", "45%", "50%", "45%"],
    mt: ["0%", "0%", "0%", "0%", "0%", "0%"],
    ml: ["9%", "7%"],
  },
  title: {
    fontSize: ["17px", "17px", "20.5px", "27.5px", "35.5px", "35.5px"],
    fontWeight: "300",
    color: "headingColor",
    letterSpacing: "-0.025em",
    mb: "20px",
  },
  subTitle: {
    fontSize: ["26px", "40px", "44px", "48px", "52px", "58px"],
    fontWeight: "700",
    color: "#98e044",
    letterSpacing: "-0.025em",
    mb: "20px",
  },
  description: {
    fontSize: ["14px", "15.5px", "17.5px", "18.5", "18.5px", "20.5px"],
    fontWeight: "350",
    color: "headingColor",
    lineHeight: "1.75",
    textAlign: "justify",
    marginTop: "10%",
    marginBottom: "10%",
  },

  imageOne: {
    mb: "40px",
    ml: "auto",
    mr: "auto",
  },
  imageTwo: {
    ml: "auto",
    mr: "auto",
  },
}

export default PaymentSection
