/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Box from "../../../components/Box"
import Image from "../../../components/Image"
import { ClientsImage } from "./style"

const OurPartners = ({ row }) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        CLIENTS {
          title
          image {
            publicURL
          }
        }
      }
    }
  `)

  return (
    <Box {...row}>
      {Data.dataJson.CLIENTS.map((item, index) => (
        <ClientsImage key={`client-${index}`}>
          <Image
            src={item.image.publicURL}
            alt={item.title}
            title={item.title}
            className="img-flex"
          />
        </ClientsImage>
      ))}
    </Box>
  )
}

OurPartners.propTypes = {
  row: PropTypes.object,
}

OurPartners.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    ml: ["0%", "0%", "0%", "0%", "10%", "10%"],
    mr: ["0%", "0%", "0%", "0%", "10%", "10%"],
  },
}

export default OurPartners
