import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../components/Image"
import Fade from "react-reveal/Fade"
import PropTypes from "prop-types"
import Box from "../../../components/Box"
import Text from "../../../components/Text"
import Heading from "../../../components/Heading"
import FeatureBlock from "../../../components/FeatureBlock"
import TeamSectionWrapper, {
  SocialLinks,
  ContainerA,
} from "./teamSection.style"

const TeamSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  header,
  sectionSubTitle,
  memberName,
  designation,
  contentStyle,
  desc,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        TEAM_MEMBER {
          title
          description
          secdescription
          data {
            name
            role
            desc
            id
            thumbnail_url {
              publicURL
            }
            social_links {
              id
              logo {
                publicURL
              }
              url
            }
          }
        }
      }
    }
  `)

  return (
    <TeamSectionWrapper id="teamSection">
      <div {...sectionHeader}>
        <Fade bottom cascade>
          <Text content={Data.dataJson.TEAM_MEMBER.title} {...header} />
          <Text
            {...sectionSubTitle}
            content={Data.dataJson.TEAM_MEMBER.description}
          />
          <Heading
            {...sectionTitle}
            className="text-flex"
            content={Data.dataJson.TEAM_MEMBER.secdescription}
          />
        </Fade>
      </div>
      <ContainerA>
        <Box {...row} className="row-col-flex">
          {Data.dataJson.TEAM_MEMBER.data.map((member, index) => (
            <Box {...col} key={`team_key-${index}`}>
              <FeatureBlock
                id={`member-${member.id}`}
                iconposition="top"
                icon={
                  <div className="icon-wrapper">
                    <Image
                      className="ceo"
                      src={member.thumbnail_url.publicURL}
                      alt={`Team member photo ${member.id}`}
                    />
                  </div>
                }
                contentStyle={contentStyle}
                title={<Heading content={member.name} {...memberName} />}
                description={
                  <Fragment>
                    <Box className="boxSizees">
                      <Text content={member.role} {...designation} />
                      <Text content={member.desc} {...desc} />
                    </Box>

                    {member.id === 2 && (
                      <SocialLinks>
                        {member.social_links.map((social, index) => (
                          <a
                            href={social.url !== "" && social.url}
                            key={`profile_id-${index}`}
                            aria-label={social.icon}
                          >
                              <img src={social.logo.publicURL} alt="" />
                          </a>
                        ))}
                      </SocialLinks>
                    )}
                  </Fragment>
                }
              />
            </Box>
          ))}
        </Box>
      </ContainerA>
    </TeamSectionWrapper>
  )
}

// TeamSection style props
TeamSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  memberName: PropTypes.object,
  designation: PropTypes.object,
}

// TeamSection default style
TeamSection.defaultProps = {
  // section header default style
  sectionHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  // sub section default style
  sectionSubTitle: {
    fontSize: ["17px", "17px", "24px", "30px", "40.5px", "40.5px"],
    fontWeight: "700",
    color: "#98e044",
    letterSpacing: "-0.025em",
    lineHeight: "1.5",
    textAlign: "center",
    m: "30%",
  },
  // section title default style
  sectionTitle: {
    fontSize: ["14px", "15.5px", "17.5px", "18.5", "18.5px", "20.5px"],
    fontWeight: "350",
    color: "#b6b6b7",
    lineHeight: "1.75",
    textAlign: "justify",
    mb: "5%",
    mt: "5%",
  },

  header: {
    color: "#b5b5b6",
    fontSize: ["17px", "17px", "20.5px", "27.5px", "35.5px", "35.5px"],
    lineHeight: "55px",
    fontWeight: "700",
    width: "100%",
    height: "100%",
    marginBottom: "24px",
    textAlign: "center !important",
  },
  // Team member row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    justifyContent: "center",
    textAlign: "center !important",
    ml: "-15px",
    mr: "-15px",
  },
  // Team member col default style
  col: {
    width: [1, 1 / 2, 1 / 3, 1 / 3],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  // Team member content default style
  contentStyle: {
    textAlign: "center",
    mt: "25px",
  },
  // Team member memberName default style
  memberName: {
    fontSize: ["26px", "30px", "26px", "25px", "30px", "30px"],
    fontWeight: "800",
    color: "#353535",
    lineHeight: "1.5",
    mb: "8px",
    letterSpacing: "-0.34px",
  },
  // Team member description default style
  designation: {
    fontSize: ["18.5px", "18.5px", "16.5px", "18.5px", "21.5px", "21.5px"],
    lineHeight: "1.55",
    color: "#dddddd",
    mb: 0,
  },
  desc: {
    width: "80%",
    fontSize: ["14px", "14px", "10px", "12px", "14px", "18.5px"],
    lineHeight: "1.51",
    color: "#9fa3a7",
    mt: ["5%"],
    ml: ["10%", "10%", "10%", "11%", "10%", "15%"],
    // mr: ['15%', '15%', '0%', '15%', '15%', '15%'],
    textAlign: "justify",
  },
}

export default TeamSection
