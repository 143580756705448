import React from "react"
import { ThemeProvider } from "styled-components"
import Sticky from "react-stickynode"
import { theme } from "../theme"
import { GlobalStyle, ContentWrapperNoBg } from "../containers/style"
import { ResetCSS } from "../assets/css/style"
import Navbar from "../containers/Global/Navbar"
import FooterSection from "../containers/Global/Footer"
import Slider from "../containers/About/Slider"
import Partners from "../containers/About/Partners"
import Newsletter from "../containers/About/Newsletter"
import OurVision from "../containers/About/OurVision"
import TeamSection from "../containers/About/TeamSection"
import Cookies from "../containers/Landing/Cookies"
// import OurStory from "../containers/About/OurStory"
import { DrawerProvider } from "../contexts/DrawerContext"
import { ParallaxProvider } from "react-scroll-parallax"
import SEO from "../components/seo"

export default () => {
    return (
        <ThemeProvider theme={theme}>
            <ParallaxProvider>
                <SEO title="Agrabiz" />
                <ResetCSS />
                <Cookies />
                <GlobalStyle />
                <ContentWrapperNoBg>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                        <DrawerProvider>
                            <Navbar />
                        </DrawerProvider>
                    </Sticky>
                    <Slider />
                    {/* <OurStory /> */}
                    <TeamSection />
                    <OurVision/>
                    <Partners />
                    {/* <Newsletter /> */}
                    <FooterSection />
                </ContentWrapperNoBg>
            </ParallaxProvider>
        </ThemeProvider>
    )
}
