
import styled from "styled-components"
import bg from '../../../assets/images/about/visionBg.png'

export const SliderWrapper = styled.div`

margin-top: 2%;
margin-left:50px;
width:100%;
@media (max-width: 425px) {
    margin-left:40px;
  }
.glide__bullets {
   
  margin-left:38px;
  text-align:left;
  @media (max-width: 1440px) {
    margin-left:17px;
  }
  @media (max-width: 425px) {
    margin-left:0px;
  }
  @media (max-width: 575px) {
    margin-top: 0px;
  }
  .glide__bullet {
    width: 10px;
    height: 10px;
    background: #cbcbcb;
    margin: 4px;
    transition: 0.15s ease-in-out;
    &:hover {
      background: #ff7e00;
    }
    &.glide__bullet--active {
      background: #ff7e00;
    }
    @media (max-width: 575px) {
      width: 7px;
      height: 7px;
    }
  }
}
`;

export const VisionWrapper = styled.div`
background-image: url(${bg}); 
padding-top: 50px;
height: 100%;
overflow: hidden;
position: relative;

@media only screen and (min-width: 1201px) and (max-width: 1440px) {
  min-height: 100vh;
}
@media only screen and (max-width: 1099px) {
  min-height: 100%;
}
@media only screen and (max-width: 480px) {
  padding-top: 90px;
}
> div.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: calc(802px - 100px);
  @media only screen and (min-width: 1201px) and (max-width: 1440px) {
    min-height: calc(100vh - 100px);
    margin-top: 35px;
    align-items: flex-start;
  }
  @media only screen and (max-width: 1099px) {
    min-height: 100%;
  }
  @media only screen and (max-width: 480px) {
    flex-wrap: wrap;
  }
}
`;

export const TestimonialSlideWrapper = styled.div`

    margin-top: 20%;
    margin-left:30px;
    width:140%;
  .glide__bullets {
   
    margin-left:38px;
    text-align:left;
    @media (max-width: 575px) {
      margin-top: 0px;
    }
    .glide__bullet {
      width: 10px;
      height: 10px;
      background: #cbcbcb;
      margin: 4px;
      transition: 0.15s ease-in-out;
      &:hover {
        background: #98e044;
      }
      &.glide__bullet--active {
        background: #98e044;
      }
    }
  }
`;

export const TestimonialItem = styled.div`
  position: relative;
  display: block;
  background: transparent;
  font-family: Raleway;
  padding: 38px;
  @media (max-width: 1440px) {
    padding: 20px;
  }
  @media (max-width: 575px) {
    padding: 0px;
  }
`;

export const TestimonialMeta = styled.div`
  display: flex;
  align-items: left;
  width:100%;
  height:100%;
`;

export const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
`;
