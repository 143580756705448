import React from "react"
import PropTypes from "prop-types"
import Box from "../../../components/Box"
import Heading from "../../../components/Heading"
import Text from "../../../components/Text"
import Container from "../../../components/Container"
import OurPartners from "../../Global/OurPartners"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import {Partner} from "../../Landing/Partners/style"

const PartnersSection = ({
  sectionWrapper,
  sectionHeader,
  sectionSubTitle,
  sectionTitle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        ABOUT_PARTNERS {
          title
          description
        }
      }
    }
  `)

  return (
    <Partner>

    <Box {...sectionWrapper} as="section">
      <Box {...sectionHeader}>
        <Fade bottom cascade>
          <Text
            {...sectionSubTitle}
            content={Data.dataJson.ABOUT_PARTNERS.title}
          />
          <Heading
            {...sectionTitle}
            className='text-flex'
            content={Data.dataJson.ABOUT_PARTNERS.description}
          />
        </Fade>
      </Box>

      <Container nogutter width="1200px">
        <Fade bottom>
          <OurPartners />
        </Fade>
      </Container>
    </Box>
    </Partner>

  )
}

PartnersSection.propTypes = {
  sectionWrapper: PropTypes.object,
  sectionHeader: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  sectionTitle: PropTypes.object,
}

PartnersSection.defaultProps = {
  sectionWrapper: {
    pt: ["20%", "60px", "140px", "80px", "80px"],
    pb: ["60px", "80px", "100px", "130px", "130px"],
  },
  sectionHeader: {
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  // sub section default style
  sectionSubTitle: {
    fontSize: ["17px", "17px", "24px", "30px", "40.5px", "40.5px"],
    fontWeight: "700",
    color: "#98e044",
    letterSpacing: "-0.025em",
    lineHeight: "1.5",
    textAlign: "center",
    m: "30%",
  },
  // section title default style
  sectionTitle: {
    fontSize: ["14px", "15.5px", "17.5px", "18.5", "18.5px", "20.5px"],
    fontWeight: "350",
    color: "#b6b6b7",
    lineHeight: "1.75",
    textAlign: "justify",
    mb: "5%",
    mt: "5%",
  },
}

export default PartnersSection
